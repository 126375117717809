/** @format */

import React from 'react';
import Fade from 'react-reveal/Fade';
import data from '../yourdata';
import resume from '../pdfs/resume2.pdf';

const About = () => {
    return (
        <div className="section" id="about">
            <div className="container">
                <div className="about-section">
                    <div className="content">
                        <h1>About Me</h1>
                        <p className="firstp">
                            I'm a product designer with a passion for design,
                            technology, and entrepreneurship.
                        </p>

                        <p>
                            I love working with clients (especially startups)
                            and teams to bring their ideas and vision to life.
                            Whether it be through mock-ups so they can see what
                            their vision could look like or through actually
                            going through the whole process and designing and
                            developing their application so that they can share
                            it with the world.
                        </p>
                        <p>
                            Currently, I'm working on my consulting skills at
                            Capgemini as an digital customer experience
                            consultant in their DCX business unit.
                        </p>
                        <p>
                            Outside of work you can find me trying new recipes,
                            traveling, or practicing Spanish or Portuguese.
                        </p>
                    </div>
                    <div className="image-wrapper">
                        <img
                            src={data.aboutImage}
                            className="aboutPic"
                            alt="about"></img>
                    </div>
                </div>
                {/* <div style={{ marginTop: '25px' }} id="content-desktop">
                    <embed src={resume} width="800px" height="1130px" />
                </div>
                <div style={{ marginTop: '10px' }} id="content-mobile">
                    <embed src={resume} width="100%" height="600px" />
                </div> */}
            </div>
        </div>
    );
};

export default About;
