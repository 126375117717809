/** @format */

// Skills Icons
import figmaIcon from './images/figma.svg';
import cssIcon from './images/css.svg';
import reactIcon from './images/react.svg';
import jsLogo from './images/jsLogo.png';
import client from './images/client.png';
import codeIcon from './images/code.svg';

// Social Icon
import githubIcon from './images/github.svg';
import codepenIcon from './images/codepen.svg';
import dribbbleIcon from './images/dribbble.svg';
import instagramIcon from './images/instagram.svg';
import linkedinIcon from './images/linkedin.svg';

//Card Images
import otherwork from './images/otherwork.jpg';
import penskecover from './images/penskecoverlarge.png';
import mallardbayc from './images/mbcover.png';
import liveunlimitedc from './images/lucover.jpg';

//About Image
import me from './images/me2.jpg';

//Testimonials Images
import gina from './images/gina.jpeg';
import chris from './images/chris.jpeg';

export default {
    //(Please Do Not Remove The comma(,) after every variable)
    //Change The Website Template

    //   Header Details ---------------------
    name: 'Ian',
    headerTagline: [
        //Line 1 For Header
        'I design and build',
        //Line 2 For Header
        'digital products.',
        //Line 3 For Header
    ],
    //   Header Paragraph
    headerParagraph:
        "I'm a Product Designer and Front-End Devloper with a passion for design, technology, and entrepreneurship. También hablo español!",

    //Contact Email
    contactEmail: 'ian@ndrepont.com',

    // End Header Details -----------------------

    // Work Section ------------------------
    projects: [
        {
            role: 'Product Design, User Research',
            title: 'Improving Vehicle Notification Subscriptions',
            case: 'Penske Truck Rental',
            para:
                'Refined notifications to be actionable and timely, improved subscription list, and enhanced email clarity.',
            imageSrc: penskecover,
            url: 'penske',
        },
        {
            role: 'Product Design, User Research, Front-End Development',
            title: 'Creating Community by Promoting Engagement',
            case: 'Live Unlimited',
            para:
                'Centralized community platform for profiles, posts, events, RSVPs, and member connections.',
            imageSrc: liveunlimitedc,
            url: 'liveunlimited',
        },

        {
            role: 'Product Design',
            title: 'AirBnb for Hunting and Fishing Experiences',
            case: 'Mallard Bay Outdoors',
            para:
                'Revamped design to better align with the brand, improving modernity and usability.',
            imageSrc: mallardbayc,
            url: 'mallardbay',
        },
        {
            role: '',
            title: 'Other Work',
            case: '',
            para: 'A collection of app, website, and one-off designs.',
            imageSrc: otherwork,
            url: 'otherwork',
        },
    ],

    // End Work Section -----------------------

    // About Secton --------------
    aboutParaOne:
        "I'm a product designer with a passion for design, technology, and entrepreneurship.",
    aboutParaTwo:
        'I love working with clients (especially startups) and teams to bring their ideas and vision to life. Whether it be through mock-ups so they can see what their vision could look like or through actually going through the whole process and designing and developing their application so that they can share it with the world.',
    aboutParaThree:
        "Currently, I'm working on my consulting skills at Capgemini as an digital customer experience design consultant in their DCX business unit.",
    aboutParaFour:
        'Outside of work you can find me trying new recipes, traveling, or practicing Spanish or Portuguese.',
    aboutImage: me,

    //   End About Section ---------------------

    // Skills Section ---------------

    //   Import Icons from the top and link it here

    skills: [
        {
            img: figmaIcon,
            para:
                'I have built dozens of high-fidelity mockups and prototypes in Figma and Adobe XD.',
        },
        {
            img: client,
            para:
                'I have experience working closely with clients to iteratively design their applications and conducting user testing to validate designs and their usability.',
        },
        {
            img: jsLogo,
            para: `I have experience building websites (like this one!) and mobile application front-ends using JavaScript, HTML, and CSS and utilizing technologies like React Native, React, Gatsby, and KnockoutJS.  `,
        },
    ],

    // End Skills Section --------------------------

    // Testimonials Section ------------------

    testimonial: [
        {
            img: chris,
            quote: '"Wow, you\'re smarter than I thought."',
            title: 'Founder of Waitr App & Mallard Bay Outdoors',
            name: 'Chris Meaux',
        },
        {
            img: gina,
            quote:
                '"Ian was extremely professional from the get go. Ian clearly communicated his designs and ideas, and had great time and project management throughout the whole project. I would hire him again with no hesitation truly enjoyed working with him."',
            title: 'Founder of Q6 Video Reviews',
            name: 'Gina Fitzgerald',
        },
    ],

    // End Testimonials Section ---------------

    //   Contact Section --------------

    contactSubHeading: "Let's connect",
    social: [
        // Add Or Remove The Link Accordingly
        // { img: githubIcon, url: 'https://github.com/iandrepont' },
        // {
        //   img: codepenIcon,
        //   url: "https://www.codepen.com/",
        // },
        // {
        //   img: dribbbleIcon,
        //   url: "https://dribbble.com/iandrepont",
        // },
        // {
        //     img: instagramIcon,
        //     url: 'https://www.instagram.com/iandrepont',
        // },
        { img: linkedinIcon, url: 'https://www.linkedin.com/in/iandrepont/' },
    ],

    // End Contact Section ---------------
};
